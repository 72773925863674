@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  font-style: normal;
  font-size: 14px;
  font-display: swap;
  src: url("./fonts/pretendard/Pretendard-Thin.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("./fonts/pretendard/Pretendard-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("./fonts/pretendard/Pretendard-Light.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./fonts/pretendard/Pretendard-Regular.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("./fonts/pretendard/Pretendard-Medium.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("./fonts/pretendard/Pretendard-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("./fonts/pretendard/Pretendard-Bold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("./fonts/pretendard/Pretendard-ExtraBold.woff") format("woff");
}
