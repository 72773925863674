.login-root {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  display: block;
  background-color: #F5F7FA;
  z-index: 700;
}

.login-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  width: 80%;
  max-width: 960px;
  padding: 4rem 1.5rem;
  background-color: #F5F7FA;
  border-radius: 30px;
  /* box-shadow: 0 5px 15px rgb(0 0 0 / 10%); */
  text-align: center;
  margin-top: -2rem;
}

.TextField-without-border-radius fieldset {
  border-radius: 25px;
}

.copy-warapper {
  position: absolute;
  bottom: 30px;
}

.copyright {
  width: 100%;
  margin: 0 auto;
  color: #999;
  font-size: 0.8rem;
  line-height: 1rem;
}
